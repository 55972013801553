define("discourse/plugins/discourse-uipath-mvp/discourse/templates/admin/plugins-mvp", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mvp-award">
    <div class="mvp-award__header">
      <h3>{{i18n "discourse_uipath_mvp.admin.award.title"}}</h3>
    </div>
    <MvpAwardForm />
  </div>
  */
  {
    "id": "bCBXLW/i",
    "block": "[[[10,0],[14,0,\"mvp-award\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mvp-award__header\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"discourse_uipath_mvp.admin.award.title\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n\"],[13]],[],false,[\"div\",\"h3\",\"i18n\",\"mvp-award-form\"]]",
    "moduleName": "discourse/plugins/discourse-uipath-mvp/discourse/templates/admin/plugins-mvp.hbs",
    "isStrictMode": false
  });
});