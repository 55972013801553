define("discourse/plugins/discourse-uipath-mvp/discourse/routes/user-activity-high-impact", ["exports", "@ember/service", "discourse/routes/user-topic-list", "I18n"], function (_exports, _service, _userTopicList, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivityHighImpact extends _userTopicList.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    templateName = "user-activity-high-impact";
    controllerName = "user-activity-high-impact";
    queryParams = {
      tag: {
        refreshModel: true
      }
    };
    beforeModel() {
      if (this.site.mvp_features_restricted && !this.currentUser?.can_see_mvp_features) {
        this.router.replaceWith("/404");
      }
    }
    model(_ref) {
      let {
        tag
      } = _ref;
      if (this.siteSettings.mvp_high_impact_activity_category !== "") {
        const filterParams = {
          category: this.siteSettings.mvp_high_impact_activity_category
        };
        if (tag && tag !== "all") {
          filterParams["tags"] = tag;
        }
        return this.store.findFiltered("topicList", {
          filter: `topics/created-by/${this.modelFor("user").username_lower}`,
          params: filterParams
        });
      } else {
        return {
          topics: []
        };
      }
    }
    setupController(controller, model) {
      // TODO: Extending UserTopicListRoute, there is no way to do this now
      //       without touching the model itself
      model.emptyState = {
        title: _I18n.default.t("discourse_uipath_mvp.user.activity.high_impact.none_yet"),
        body: ""
      };
      super.setupController(controller, model);
    }
    titleToken() {
      return _I18n.default.t("discourse_uipath_mvp.user_activity.title");
    }
  }
  _exports.default = UserActivityHighImpact;
});